import { BasicText, FlexContainer, TextAlign, VerticalAlign } from "@pmi/ui-react";
import styles from "./NotFound.module.scss";
const NotFound = () => {
  return (
    <FlexContainer className={styles.notFound}>
      <FlexContainer verticalAlign={VerticalAlign.Middle} className={styles.innerContainer}>
        <BasicText textAlign={TextAlign.Center}>Page not found</BasicText>
      </FlexContainer>
    </FlexContainer>
  );
};

export default NotFound;