import { FunctionComponent, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import "./assets/styles/index.scss";
import NotFound from "./components/NotFound";

const Engraving = lazy(() => import("./app/Engraving"));
export const notFound = "*";

const App: FunctionComponent = () => {
  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}/engraving`} element={<Engraving />} />
      <Route path={notFound} element={<NotFound />} />
    </Routes>
  );
};

export default App;
